<template>
    <div class="container_con">
        <el-card v-if="form" >
            <div class="con_title"><span></span> 基本信息</div>
            <div class="flex" style="align-items: flex-start;">
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">客商名称:</div>
                        <div class="flex1">{{form.merchant_name}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">客商编号:</div>
                        <div class="flex1">{{form.merchant_no}}</div>
                    </div>
                </div>
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">是否销售:</div>
                        <div class="flex1">{{form.is_sales==1?'是':'否'}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">是否进货:</div>
                        <div class="flex1">{{form.is_purchase==1?'是':'否'}}</div>
                    </div>
                </div>
            </div>
            
            <div class="con_title" style="margin-top: 30px;"><span></span> 营业执照</div>
            <div class="flex" style="align-items: flex-start;">
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">统一社会信用代码:</div>
                        <div class="flex1">{{form.bl_code}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">名称:</div>
                        <div class="flex1">{{form.bl_name}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">法定代表人:</div>
                        <div class="flex1">{{form.bl_legal_person}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">成立时间:</div>
                        <div class="flex1">{{form.bl_established_date}}</div>
                    </div>
                </div>
                <div class="items flex flex_wrap">
                    
                    <div class="flex_ac item">
                        <div class="tt">营业时间:</div>
                        <div class="flex1">{{form.bl_operating_period || '长效期'}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">住所:</div>
                        <div class="flex1">{{form.bl_residence}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">经营范围:</div>
                        <div class="flex1">{{form.bl_business_scope}}</div>
                    </div>
                </div>
            </div>
            <div class="flex" style="align-items: flex-start;">
                <div class="items flex flex_wrap" style="width: 100%;">
                    <div class="flex_ac item">
                        <div class="tt">证件图片:</div>
                        <div class="flex1 flex">
                            <div class="picture-card-img" v-for="(item,index) in imgs2" :key="index">
                                <img :src="$http+item.url" class="picture-card-imgs" alt="">
                                <div class="op_btns"><i @click.stop="PicturePreview({url:$http+item.url})"
                                        class="el-icon-zoom-in"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
                
            <div class="con_title" style="margin-top: 30px;"><span></span> 医疗机构执业许可证</div>
            <div class="flex" style="align-items: flex-start;">
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">机构名称:</div>
                        <div class="flex1">{{form.mi_institution_name}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">地址:</div>
                        <div class="flex1">{{form.mi_address}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">法定代表人:</div>
                        <div class="flex1">{{form.mi_legal_person}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">主要负责人:</div>
                        <div class="flex1">{{form.mi_main_incharge}}</div>
                    </div>
                    
                    
                </div>
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">登记号:</div>
                        <div class="flex1">{{form.mi_license_number}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">有效期时间:</div>
                        <div class="flex1">{{form.mi_valid_from?(form.mi_valid_from+'至'+form.mi_valid_until):''}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">诊疗科目:</div>
                        <div class="flex1">{{form.mi_treatment_subjects}}</div>
                    </div>
                </div>
            </div>
            <div class="flex" style="align-items: flex-start;">
                <div class="items flex flex_wrap" style="width: 100%;">
                    <div class="flex_ac item">
                        <div class="tt">证件图片:</div>
                        <div class="flex1 flex">
                            <div class="picture-card-img" v-for="(item,index) in imgs3" :key="index">
                                <img :src="$http+item.url" class="picture-card-imgs" alt="">
                                <div class="op_btns"><i @click.stop="PicturePreview({url:$http+item.url})"
                                        class="el-icon-zoom-in"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
            
            <div class="con_title" style="margin-top: 30px;"><span></span> 医疗器械经营许可证</div>
            <div class="flex" style="align-items: flex-start;">
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">企业名称:</div>
                        <div class="flex1">{{form.mebl_company_name}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">许可证编号:</div>
                        <div class="flex1">{{form.mebl_permit_number}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">发证日期:</div>
                        <div class="flex1">{{form.mebl_issue_date}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">有效期时间:</div>
                        <div class="flex1">{{form.mebl_issue_date?(form.mebl_issue_date+'至'+form.mebl_valid_until):''}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">经营方式:</div>
                        <div class="flex1">{{form.mebl_operation_mode}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">经营范围:</div>
                        <div class="flex1">{{form.mebl_business_scope}}</div>
                    </div>
                </div>
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">法定代表人:</div>
                        <div class="flex1">{{form.mebl_legal_person}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">企业代表人:</div>
                        <div class="flex1">{{form.mebl_ceo}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">经营场所:</div>
                        <div class="flex1">{{form.mebl_business_premises}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">库房地址:</div>
                        <div class="flex1">{{form.mebl_warehouse_address}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">住所:</div>
                        <div class="flex1">{{form.mebl_residence}}</div>
                    </div>
                </div>
            </div>
            <div class="flex" style="align-items: flex-start;">
                <div class="items flex flex_wrap" style="width: 100%;">
                    <div class="flex_ac item">
                        <div class="tt">证件图片:</div>
                        <div class="flex1 flex">
                            <div class="picture-card-img" v-for="(item,index) in imgs4" :key="index">
                                <img :src="$http+item.url" class="picture-card-imgs" alt="">
                                <div class="op_btns"><i @click.stop="PicturePreview({url:$http+item.url})"
                                        class="el-icon-zoom-in"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="con_title" style="margin-top: 30px;"><span></span> 第二类医疗器械经营</div>
            <div class="flex" style="align-items: flex-start;">
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">企业名称:</div>
                        <div class="flex1">{{form.smdorc_company_name}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">备案号:</div>
                        <div class="flex1">{{form.smdorc_filing_number}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">经营方式:</div>
                        <div class="flex1">{{form.smdorc_operation_mode}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">法定代表人:</div>
                        <div class="flex1">{{form.smdorc_legal_person}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">企业负责人:</div>
                        <div class="flex1">{{form.smdorc_ceo}}</div>
                    </div>
                    
                </div>
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">经营场所:</div>
                        <div class="flex1">{{form.smdorc_business_premises}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">住所:</div>
                        <div class="flex1">{{form.smdorc_residence}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">库房地址:</div>
                        <div class="flex1">{{form.smdorc_warehouse_address}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">经营范围:</div>
                        <div class="flex1">{{form.smdorc_business_scope}}</div>
                    </div>
                </div>
            </div>
            <div class="flex" style="align-items: flex-start;">
                <div class="items flex flex_wrap" style="width: 100%;">
                    <div class="flex_ac item">
                        <div class="tt">证件图片:</div>
                        <div class="flex1 flex">
                            <div class="picture-card-img" v-for="(item,index) in imgs5" :key="index">
                                <img :src="$http+item.url" class="picture-card-imgs" alt="">
                                <div class="op_btns"><i @click.stop="PicturePreview({url:$http+item.url})"
                                        class="el-icon-zoom-in"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="bottom_btn" style="justify-content: flex-start;">
                <el-button @click="$router.go(-1)">返回</el-button>
            </div>

        </el-card>
        <!-- 上传图片预览 -->
        <el-dialog :close-on-click-modal="false" title="图片预览" width="600px" v-model="uploadImgVisible">
            <img style="width: 100%;" :src="uploadImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
    // 上传组件
    export default {
        name: "productDetail",
        data() {
            return {
                form: '',
                imgs2: [],
                imgs3: [],
                imgs4: [],
                imgs5: [],
                // 上传图片
                uploadImageUrl: '',
                uploadImgVisible: false,
            };
        },
        created() {
            this.id = this.$route.query.id;
            this.get_data()
        },
        methods: {
            PicturePreview(file) {
                this.uploadImageUrl = file.url;
                this.uploadImgVisible = true;
            },
            get_data() {
                this.$httpGet("/backend/Merchant/read", {
                    merchant_id: this.id
                }).then((res) => {
                    if (res.status == 200) {
                        let _data = res.data;
                        this.form = _data;
                        // 图片还原
                        let merchant_image=_data.merchant_image || [];
                        for(let i=0;i<merchant_image.length;i++){
                            let type=merchant_image[i].type+1
                            this['imgs'+type].push({
                                id:merchant_image[i].id,
                                url: '/storage/' + merchant_image[i].save_path.split('/storage/')[1]
                            })
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .items {
        width: 50%;
        padding-right: 30px;
    }

    .items .item {
        padding: 10px;
        width: 100%;
        padding-right: 30px;
        line-height: 24px;
        color: #333;
        font-size: 14px;
    }

    .items .item .tt {
        color: #888;
        width: 150px
    }

    .avatar {
        height: 80px;
    }
</style>
